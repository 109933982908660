import React from 'react';
import './TonTechnologies.css';

export const TonTechnologies = () => {
  return (
    <div className="TonTechnologies">
      <p> TON TECHNOLOGIES 2023 </p>
    </div>
  );
}
